
import { defineComponent, ref } from "vue";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import { useRoute, useRouter } from "vue-router";
import Loading from "@/components/UI/Loading";
import qs from "qs";
import dateFormat from "@/utils/dateFormat";
export default defineComponent({
  name: "ExchangeDetail",
  components: {
    Loading,
  },
  setup() {
    const route = useRoute();
    const num = route.query.num;
    const entity = ref(null);
    const product = ref(null);
    axios
      .get(`/M/User/JifenDetail`, {
        params: {
          validate: true,
          num,
        },
      })
      .then((res) => {
        if (res.data.success) {
          entity.value = res.data.obj.entity;
          product.value = res.data.obj.product;
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const loadOption = {
      text: "加载中...",
      color: "#b9135a",
      textColor: "#b9135a",
    };

    return {
      entity,
      loadOption,
      dateFormat,
      product
    };
  },
});
